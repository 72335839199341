import React from "react";

const Home = () => {
  return (
    <div style={{ height: "100vh", textAlign: "center", display: "grid", placeItems: "center" }}>
      <h1>Welcome to Talkgram Meeting</h1>
    </div>
  );
};

export default Home;
