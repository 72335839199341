import { create } from "zustand";

export const useMeetingStore = create((set) => ({
  meetingData: null,
  isFetching: true,
  showModal: false,
  allClosed: false,
  updateFetching: (fetching) => set(() => ({ isFetching: fetching })),
  updateMeetingData: (data) => set(() => ({ meetingData: data })),
  setShowModal: (data) => set(() => ({ showModal: data })),
  setAllClosed: (data) => set(() => ({ allClosed: data })),
}));
